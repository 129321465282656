import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const mainFont = 'Century Gothic'

const white = '#fff'
const black = '#000'
const primaryMain = '#383838' //noir pas foncé
const primaryLight = '#383838'
// const primaryLight = '#6B6B6B'
const secondaryMain = '#383838'
const greyLight = '#F0F0F0'

const imp = '!important'

// const f11 = `11px`
const f12 = `12px`
const f13 = `13px`
const f14 = `14px`
const f15 = `15px`
const f16 = `16px`
const f18 = `18px`

// const f20 = `20px`
// const f22 = `22px`
const f24 = `24px`
const f25 = `25px`
// const f30 = `30px`
const f32 = `32px`

const body = {
  fontSize: f14,
  lineHeight: 1.6,
}
const body2 = {
  fontSize: f13,
  lineHeight: 1.67,
}

const theme = createMuiTheme({
  themeFont: {
    //WARNING FONT NAME WITH SPACE SHOULD HAVE A +. NEXT JS TRIGGER BAD URL FOR GOOGLE FONT
    main: 'Hanken+Grotesk',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${mainFont}, sans-serif`,
    h1: {
      lineHeight: 1.15,
      ...heading(f25, f25, 700, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    h2: {
      lineHeight: 1.15,
      ...heading(f24, f24, 600, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    h3: {
      lineHeight: 1.4,
      ...heading(f18, f18, 700, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    h4: {
      lineHeight: 1.55,
      ...heading(f16, f16, 700, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    h5: {
      lineHeight: 1.25,
      ...heading(f14, f14, 600, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    h6: {
      lineHeight: 1.25,
      ...heading(f12, f12, 600, 700, mainFont, mainFont, false),
      letterSpacing: '0.2em',
    },
    body1: {
      ...body,
    },
    body2: {
      ...body2,
    },
    caption: {
      fontSize: f12,
      lineHeight: 1.17,
      fontWeight: 400,
    },
    button: {
      fontSize: f15,
      lineHeight: 1.67,
      textTransform: 'inherit',
    },
  },
  palette: {
    common: {
      black: primaryMain,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryLight,
    },
    secondary: {
      main: secondaryMain,
    },
    success: {
      main: '#2937AC',
    },
    error: {
      main: '#F55353',
    },
    warning: {
      main: '#F55353',
    },
    divider: greyLight,
    primaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    secondaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#383838',
    greyLight: '#F0F0F0',
    greyMedium: '#6B6B6B',
    greyText: '#383838',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.shipping-infos__start, .shipping-infos__end': {
          backgroundColor: `inherit !important`,
        },

        '.date_form': {
          background: `none ${imp}`,
          display: `none ${imp}`,
        },

        '.page--home>.banner': {
          backgroundColor: black,
        },

        '.footer': {
          '&__bottomRow1': {
            '@media (min-width: 1024px)': {
              flexDirection: 'row-reverse',
            },
            '&Col:nth-child(2)': {
              '@media (min-width: 1024px)': {
                textAlign: `left ${imp}`,
              },
            },
          },
          '&__bottomRow2': {
            '@media (min-width: 1024px)': {
              flexDirection: 'column',
            },
            '&Col:nth-child(1)': {
              '@media (min-width: 1024px)': {
                alignSelf: 'flex-end',
              },
            },
          },
        },

        '.section-infos': {
          backgroundColor: `${greyLight} ${imp}`,

          '& .MuiContainer-root': {
            '@media (min-width: 768px)': {
              display: 'flex',
            },
          },

          '& .MuiGrid-container': {
            '@media (min-width: 768px)': {
              flexDirection: 'column',
              flexWrap: 'nowrap',
              alignItems: 'stretch',
              gap: '16px',
            },
          },

          '& .MuiGrid-item': {
            '@media (min-width: 768px)': {
              maxWidth: 'none',
            },
          },
        },

        '.section-discover': {
          '&__subtitle': {
            textAlign: 'center',
          },
          '& .discover__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
            '&:hover': {
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            },
          },
        },

        '.header': {
          '&__layout': {
            '@media (min-width: 1024px)': {
              height: '172px !important',
              display: 'grid !important',
              gridTemplateRows: '140px 32px !important;',
              gridTemplateColumns: 'repeat(3, 1fr) !important',
            },
          },
          '&__logo': {
            height: '50px !important',
            '@media (min-width: 1024px)': {
              height: '92px !important',
            },
          },
          // menu
          '&__center': {
            '@media (min-width: 1024px)': {
              gridColumnStart: 1,
              gridColumnEnd: 4,
              gridRowStart: 2,
              gridRowEnd: 3,
              width: 'unset !important',
              justifyContent: 'center !important',
            },
          },
          // title logo
          '&__left': {
            '@media (min-width: 1024px)': {
              gridColumnStart: 2,
              gridColumnEnd: 3,
              gridRowStart: 1,
              gridRowEnd: 2,
              width: 'unset !important',
              justifyContent: 'center !important',
            },
          },
          // search
          '&__right': {
            '@media (min-width: 1024px)': {
              gridColumnStart: 3,
              gridColumnEnd: 4,
              gridRowStart: 1,
              gridRowEnd: 2,
              width: 'unset !important',
              justifyContent: 'end !important',
            },
          },
        },

        '#hero__home__wrapper': {
          paddingTop: 172,
        },

        '.productCard': {
          '&__desc': {
            textAlign: 'center',
          },
          '&__rowCta>p': {
            flex: 'auto',
            marginRight: '-32px',
          },
        },

        '.price': {
          '&__amount': {
            display: 'inline-flex',
            flexDirection: 'row-reverse',
          },
        },
        '.product': {
          '&__head': {
            '&>div': {
              width: '100%',
            },
          },
          '&__title': {
            textTransform: 'uppercase',
          },
          '&__price': {
            fontSize: '16px',
          },
        },
        '.sizes__btn': {
          backgroundColor: 'transparent !important',
          border: '1px solid black',
          '&:hover': {
            backgroundColor: `${black} ${imp}`,
            color: `${white} ${imp}`,
          },
        },
        '.commitment': {
          color: 'white',
        },

        '.funnel': {
          '& .deliveryDate, & .returnDate': {
            color: 'white',
          },
        },
      },
    },
    MuiButton: {
      root: {
        padding: '12px 45px',
        borderRadius: 0,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        backgroundColor: black, //correspond à classe .MuiButton-contained
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        backgroundColor: black, //correspond à classe .MuiButton-containedPrimary
      },
      containedSecondary: {
        backgroundColor: black, //correspond à classe .MuiButton-containedSecondary
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiSelect: {
      root: {
        color: primaryMain,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: primaryMain,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: secondaryMain,
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
